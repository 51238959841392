<template>
  <KTCard
    :page="page"
    :isForm="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Senha atual <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="password"
          size="lg"
          v-model="form.password"
          required
        />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Nova senha <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="password"
          size="lg"
          v-model="form.new_password"
          required
        />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Confirme a nova senha <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="password"
          size="lg"
          v-model="form.confirm_password"
          required
        />
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "ProfilePasswordForm",
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-id-card",
        title: "Alterar senha",
        description: `Aqui você pode alterar sua senha. Lembre-se que é importante
          atualizar sua senha periodicamente. Para aumentar sua segurança utilize
          senha com letras maiúsculas, minúsculas, números e caracteres especiais,
          como ".", "-", "@" ou "#". Nunca utilize senhas fáceis, como data de
          nascimento, CPF, seu nome, etc.`
      },
      manual: {
        session: "profile_password",
        tipo: 0
      },
      apiResource: "profile_password",
      parentRoute: { name: "inicio" },
      form: {}
    };
  },
  methods: {
    onSubmit() {
      this.ApiSubmitAdd(this.form);
    },
    getPageData() {
      const requests = [this.Api.GetData()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].form ?? {};
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
    this.initManual(this.manual.session, this.manual.tipo);
  }
};
</script>
